let rest = 'https://quicksite.webkid.work/wp-json/wp/v2/';
const post_url =  'https://quicksite.webkid.work/mail/post.php';

// use vue component
import {logo} from './logo';
import {sns} from './sns';
import {news} from './news';
import {catchs} from './catchs';
import {business} from './business';
import {photo} from './photo';
import {recruit} from './recruit';
import {voice} from './voice';
import {works} from './works';
import {kussion} from './kussion';
import {maps} from './maps';
import {foot} from './foot';
import {privacy} from './privacy';

document.addEventListener('DOMContentLoaded', function() {
  	const scroll = new SmoothScroll('a[href*="#"]');
	
	const logo_comp = logo(rest + 'content/25');
	const sns_comp = sns(rest + 'content/19');
	const catch_comp = catchs(rest + 'content/37');
	const news_comp = news(rest + 'news/');
	const business_comp = business(rest + 'content/17');
	const photo_comp = photo(rest + 'photo/');
	const recruit_comp = recruit(rest + 'recruit/');
	const voice_comp = voice(rest + 'voice/');
	const works_comp = works(rest + 'works/');
	const kussion_comp = kussion(rest + 'content/139');
	const maps_comp = maps(rest + 'content/148');
	const foot_comp = foot(rest + 'content/18');
	const mail_comp = rest + 'content/203';
	
	const vm = new Vue({
		el: '#app',
		components: {
			'logo':logo_comp,
			'sns':sns_comp,
			'catcth':catch_comp,
			'news': news_comp,
			'business' : business_comp,
			'photo' : photo_comp,
			'recruit' : recruit_comp,
			'voice' : voice_comp,
			'works' : works_comp,
			'kussion' : kussion_comp,
			'maps' : maps_comp,
			'foot' : foot_comp,
			'privacy': privacy()
		},
		data: {
			color: '',
			demo: 'true',
			mail_toggle: false,
			set :[],
			formcheck: false,
			formsend: false,
			entry: {
                name: '',
                furigana: '',
                tel: '',
                email: '',
                post: '',
                address: '',
                context: '',
                return: '',
                syomei: '',
                message: ''
            }
		},
		methods: {
			mailsend: function(){
                axios.post(post_url , vm._data.entry)
                .then(response => {
                	if(response.data == 'success'){
						vm._data.mail_toggle = false;
						setTimeout(function(){
							vm._data.formsend = true;
						}, 800);
						setTimeout(function(){
							vm._data.formsend = false;
						}, 2800);
					}
                }).catch(error => {
                	console.log(error);
                });
			},
			checkall: function(){
				let flag = true;
				for(let k in this.entry) {
					if(k == 'syomei' | k == 'message'){
						//
					}
					else if(k == 'name' | k == 'furigana'){
						if(this.entry[k] == ''){
						   flag = false;
						}	
					}
				}
				if(!this.entry.email.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
					flag = false;
				}
				this.formcheck = flag;
			}
		},
		mounted: function(){
            axios.get(mail_comp)
                .then(function (response) {
                    vm._data.set = response.data;
					vm._data.entry.return = response.data.acf.email;
					vm._data.entry.syomei = response.data.acf.syomei;
					vm._data.entry.message = response.data.acf.message;
                })
                .catch(function (error) {
                    console.log(error);
                });
		}
	});
});

//mySwiper.update();